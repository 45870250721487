/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { Version as r } from "../core/Version.js";
class s extends r {
  constructor(r, s) {
    super(r, s, "webmap");
  }
}
export { s as Version };